<template>
  <div class="">
    <div class="">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <section
              class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3"
            >
              <div class="card">
                <div class="card-body table-responsive table-head">
                  <div class="row bor-bot">
                    <div class="col-md-8 pl-0 pb-3">
                      <h1 class="m-0 text-dark pt-2 pb-1">
                        {{ getSiteProps('summaryReports.groupedBy.discipline.desc') }}
                      </h1>
                      <p>(As of : {{ todayDate }})</p>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item">
                            <a href="/admin/dashboard">Home</a>
                          </li>
                          <li class="breadcrumb-item"><Breadcrumbs /></li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group pt-3">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="control-label">Type</label>
                              <select
                                name="filter_type"
                                id="filter_type"
                                class="form-control"
                                @change="triggerFilter"
                              >
                                <option value="">All</option>
                                <option
                                  v-for="(id, val) in membershipData"
                                  :value="val"
                                  :key="val"
                                >
                                  {{ id }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <button
                          type="button"
                          class="btn btn-primary darkblubtn"
                          @click="triggerCSVExport"
                        >
                          <i class="fas fa-download"></i> Download as CSV
                        </button>
                      </div>
                    </div>
                    <div class="col-md-5 pt-3">
                      <div class="table-responsive">
                        <table
                          class="table table-striped table-hover"
                          id="memberships-counts-summary"
                        >
                          <thead>
                            <tr>
                              <th>{{ getSiteProps('summaryReports.groupedBy.discipline.title') }}</th>
                              <th>Count</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>Total:</td>
                              <td><strong>0</strong></td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style>
.summary-csv-export-btn {
  display: none !important;
}
</style>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import $ from "jquery";

import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      todayDate: moment(new Date()).format("MM/DD/YYYY"),
      table: null,
      membershipData: [],
      summary_Reports:{},
    };
  },
  methods: {
    triggerCSVExport: function (event) {
      this.table.button(".summary-csv-export-btn").trigger();
    },
    getMembership() {
      axios
        .get(this.basePath + "api/getMemberships")
        .then((response) => {
          this.membershipData = response.data.data;
        })
        .catch((error) => {
          console.log("error getting Membership info");
        });
    },
    triggerFilter: function(event){
        this.table.draw();
    }
  },
  mounted() { 
    this.getMembership();
    this.table = $("#memberships-counts-summary").DataTable({
      dom:
        "<'row mt-2'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4'B>" +
        "<'col-sm-12 col-md-4'f>><'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          extend: "csv",
          text: '<i class="fas fa-file-csv"></i> Export',
          className: "btn-primary btn-darkblue btn summary-csv-export-btn",
          filename: "Financial Summary Report",
          title: "Financial summary report",
          footer: true,
        },
      ],
      responsive: true,
      bLengthChange: false,
      bInfo: false,
      searching: false,
      bPaginate: false,
    //   aaSorting: [],
      processing: true,
      serverSide: true,
      ajax: {
          url: process.env.VUE_APP_URL + "api/reports/diciplines-counts-summary",
          type: 'post',
          data: function(d) {
              d.filter_type = $('[name=filter_type]').val();
          }
      },
      columns: [
          {data: "division_name", name: "division_name", orderable: true, searchable: true},
          {data: "count", name: "count", orderable: true, searchable: true},
      ],
      "footerCallback": function (row, data, start, end, display) {
          var api = this.api();

          // Remove the formatting to get integer data for summation
          var intVal = i => {
              return typeof i === 'string' ? i.substring(1) : typeof i === 'number' ? i : 0;
          };

          // Total over all pages
          var total = api.column( 1 ).data().reduce( function (a, b) {
              return intVal(a) + intVal(b);
          }, 0 );

          // Update footer
          $( api.column( 1 ).footer() ).html(total);
      },
    });
  },
  computed: {
    years() {
      return new Date().getFullYear();
    },
  },
};
</script>